export class Auth0 {
  constructor(
    public token: string = '',
    public firstname: string = '',
    public id: string = '',
    public idtoken: string = '',
    public photo: string = '',
    public provider: string = '',
    public lastorig: string = '',
  ) {
  }
}
